import type { LinkWrapperProps } from "@/components/link-wrapper";
import { LinkWrapper } from "@/components/link-wrapper";
import { NextLink } from "@project/ui";
import { trackingNavClick } from "@/components/contentful/main-navigation/tracking-nav-click";
import { useRouter } from "next/router";

type QuickLinkProps = LinkWrapperProps;

const QuickLink = ({ onClick, ...props }: QuickLinkProps) => {
    const { locale, asPath } = useRouter();

    return (
        <LinkWrapper
            onClick={(e) => {
                onClick && onClick(e);

                const targetUrl = e.view.document.location.href;
                const targetType = e.type;
                const clickElementId = props.item.sys.id;
                const clickElementName = props.item.__typename;

                trackingNavClick({
                    targetUrl,
                    targetType,
                    clickElementId,
                    clickElementName,
                    locale: locale!,
                    asPath,
                });
            }}
            renderAs={NextLink}
            color="allWhite"
            _hover={{ opacity: 0.5 }}
            transition="var(--transition-property-common), var(--transition-duration-short) var(--transition-easing-base)"
            {...props}
        />
    );
};

export { QuickLink };
