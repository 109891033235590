import type { RenderNode } from "@contentful/rich-text-react-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Document } from "@contentful/rich-text-types";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import {
    Divider,
    Heading,
    Text,
    ListItem,
    OrderedList,
    UnorderedList,
    TableContainer,
    Table,
    Thead,
    Tr,
    Tbody,
    Blockquote,
    BlockquoteQuote,
    LinkPureExternal,
} from "@project/ui";
import { renderMark } from "@/lib/contentful/rich-text-renderer/render-mark";
import type { ReactNode } from "react";
import { useRouter } from "next/router";
import { getDictionaryItem } from "@/common/enums/dictionary";

export const renderTableTextWithLineBreaks = (children: ReactNode) => {
    if (Array.isArray(children) && children.length === 1 && typeof children[0] === "string") {
        const text = children[0] as string;
        return text.split("\n").reduce<ReactNode[]>((acc, textSegment: string, index: number) => {
            return [...acc, index > 0 && <br key={crypto.randomUUID()} />, textSegment];
        }, []);
    }

    return children;
};

export const renderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (node, children) => {
        return <Text>{children}</Text>;
    },
    [BLOCKS.HEADING_1]: (node, children) => (
        <Heading as="h1" size="headingXXLarge">
            {children}
        </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
        <Heading as="h2" size="headingXLarge" fontWeight="400">
            {children}
        </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
        <Heading as="h3" size="headingLarge" fontWeight="400" mt="2rem">
            {children}
        </Heading>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
        <Heading as="h4" size="headingMedium" fontWeight="400" mt="2rem">
            {children}
        </Heading>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
        <Heading as="h5" size="headingSmall" fontWeight="400" mt="2rem">
            {children}
        </Heading>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
        <Text as="p" size="xx-small">
            {children}
        </Text>
    ),
    [BLOCKS.HR]: () => <Divider />,
    [BLOCKS.UL_LIST]: (node, children) => <UnorderedList>{children}</UnorderedList>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.QUOTE]: (node) => {
        const transformedChildren = documentToReactComponents(node as unknown as Document, {
            renderMark,
            renderNode: {
                [BLOCKS.PARAGRAPH]: (_node, children) => children,
                [BLOCKS.QUOTE]: (_node, children) => children,
            },
        });

        return (
            <Blockquote my="2rem">
                <BlockquoteQuote
                    _before={{ content: "open-quote" }}
                    _after={{ content: "close-quote" }}
                >
                    {transformedChildren}
                </BlockquoteQuote>
            </Blockquote>
        );
    },
    [BLOCKS.TABLE]: (node, children) => {
        return (
            <TableContainer my="2rem">
                <Table>{children}</Table>
            </TableContainer>
        );
    },
    [BLOCKS.TABLE_ROW]: (node, children) => {
        if (node.content.every((child) => child.nodeType === "table-header-cell")) {
            return (
                <Thead>
                    <Tr>{children}</Tr>
                </Thead>
            );
        }

        return (
            <Tbody>
                <Tr>{children}</Tr>
            </Tbody>
        );
    },

    [INLINES.HYPERLINK]: (node, children) => {
        const { locale } = useRouter();
        const href = node.data.uri;

        return (
            <LinkPureExternal
                target="_blank"
                href={href}
                theme="light"
                icon="none"
                underline={true}
                title={getDictionaryItem("blankHrefTitle", locale)}
            >
                {children}
            </LinkPureExternal>
        );
    },

    [BLOCKS.EMBEDDED_ASSET]: () => {
        return <Text as="pre">Embedded Asset is not supported</Text>;
    },
    [INLINES.ASSET_HYPERLINK]: () => {
        return <Text as="pre">Inline Asset Hyperlink is not supported</Text>;
    },
};
