import type {
    LanguageSelectorItemFieldsFragment,
    MainNavigationFieldsFragment,
} from "@/components/contentful/main-navigation/__generated/main-navigation.contentful.generated";
import type { MenuProps } from "@project/ui";
import {
    HStack,
    Flex,
    Box,
    NextLink,
    Flag,
    Icon,
    Menu,
    MenuItem,
    MenuList,
    MenuButton,
} from "@project/ui";
import { useRouter } from "next/router";
import type { MouseEventHandler, ReactNode } from "react";
import { useMemo } from "react";
import { frostedGlassStyle } from "@porsche-design-system/components-react/styles";
import { trackingNavClick } from "@/components/contentful/main-navigation/tracking-nav-click";
import { useAppStore } from "@/store/app-store";

const LanguageSelectorIcon = ({ locale }: { locale: string }) => {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            width="var(--CountryFlagIcon-height)"
            height="calc(var(--CountryFlagIcon-height)* 3 / 2)"
        >
            {locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? (
                <Icon name="globe" theme="dark" />
            ) : (
                <Flag countryCode={getCountryCode(locale)} />
            )}
        </Flex>
    );
};

const LanguageSelectorLabel = ({ children }: { children: ReactNode }) => (
    <Box as="span" ml={1.5} color="allWhite" textTransform="uppercase" lineHeight={1}>
        {children}
    </Box>
);

const LanguageSelectorInnerItem = ({ children }: { children?: ReactNode }) => (
    <HStack
        width="full"
        gap={0}
        py={1.5}
        borderRadius="small"
        _hover={{
            backdropFilter: frostedGlassStyle.backdropFilter,
            backgroundColor: "rgba(255,255,255,0.1)",
        }}
    >
        {children}
    </HStack>
);

type LanguageSelectorProps = MainNavigationFieldsFragment["languageSelectorItemsCollection"] &
    Omit<MenuProps, "children">;

// returns a country code based on a locale i.e. fr-FR -> FR, en-US -> US, etc.
const getCountryCode = (locale: string) => locale.split("-")[1];

const LanguageSelector = ({ items, onClose, ...menuProps }: LanguageSelectorProps) => {
    const { locale, asPath } = useRouter();
    const typedLocale = locale!;

    const {
        state: { pageType, pageId },
    } = useAppStore();

    const localesExceptActiveLocale = useMemo(() => {
        return items.filter(
            (item): item is LanguageSelectorItemFieldsFragment => item?.locale !== typedLocale
        );
    }, [items, typedLocale]);

    const activeLocale = useMemo(() => {
        return items.filter(
            (item): item is LanguageSelectorItemFieldsFragment => item?.locale === typedLocale
        )[0];
    }, [items, typedLocale]);

    const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        const targetUrl = e.view.document.location.href ?? "/";
        const targetType = e.type;
        const clickElementId = pageId;
        const clickElementName = pageType;

        trackingNavClick({
            targetUrl,
            targetType,
            clickElementId,
            clickElementName,
            locale: locale!,
            asPath,
        });

        onClose && onClose();
    };

    return (
        <Menu gutter={6} matchWidth={true} {...menuProps}>
            {({ isOpen }) => (
                <>
                    <MenuButton height={{ base: 9, l: "navQuickLinksHeight" }}>
                        <LanguageSelectorInnerItem>
                            {activeLocale?.locale && (
                                <LanguageSelectorIcon locale={activeLocale.locale} />
                            )}
                            <LanguageSelectorLabel>{activeLocale?.label}</LanguageSelectorLabel>
                            <Icon
                                name="arrow-head-down"
                                theme="dark"
                                transform={`rotate(${isOpen ? 180 : 0}deg)`}
                                sx={{
                                    transition: `var(--transition-property-common) var(--transition-duration-short) var(--transition-easing-base)`,
                                }}
                            />
                        </LanguageSelectorInnerItem>
                    </MenuButton>
                    <MenuList
                        maxHeight="215px"
                        overflowX="hidden"
                        overflowY="auto"
                        className="scroll"
                        sx={{
                            scrollbarWidth: "none",
                            "&::webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >
                        {localesExceptActiveLocale.map(
                            (item, index) =>
                                item?.locale && (
                                    <MenuItem
                                        as={NextLink}
                                        key={`${item.label}-${item.locale}-${index}`}
                                        href="/"
                                        locale={item.locale}
                                        p={0}
                                        mb={2}
                                        _last={{ mb: 0 }}
                                        onClick={handleOnClick}
                                    >
                                        <LanguageSelectorInnerItem>
                                            <LanguageSelectorIcon locale={item.locale} />
                                            <LanguageSelectorLabel>
                                                {item.label}
                                            </LanguageSelectorLabel>
                                        </LanguageSelectorInnerItem>
                                    </MenuItem>
                                )
                        )}
                    </MenuList>
                </>
            )}
        </Menu>
    );
};

export { LanguageSelector };
