import { CldVideoTogglePlay, mediaQueryMinWidth } from "@project/ui";

const playPauseButtonOffsetBase = "var(--space-5)";
const playPauseButtonOffsetDesktop = "var(--space-10)";

export const TemporaryPlayPauseButton = () => {
    return (
        <>
            <CldVideoTogglePlay
                mobile
                sx={{
                    display: "flex",
                    position: "absolute",
                    right: [playPauseButtonOffsetBase, playPauseButtonOffsetDesktop],
                    bottom: [playPauseButtonOffsetBase, playPauseButtonOffsetDesktop],
                    zIndex: 2,
                    [mediaQueryMinWidth.md]: {
                        display: "none",
                    },
                }}
            />
            <CldVideoTogglePlay
                sx={{
                    display: "none",
                    position: "absolute",
                    right: [playPauseButtonOffsetBase, playPauseButtonOffsetDesktop],
                    bottom: [playPauseButtonOffsetBase, playPauseButtonOffsetDesktop],
                    zIndex: 2,
                    [mediaQueryMinWidth.md]: {
                        display: "flex",
                    },
                }}
            />
        </>
    );
};
