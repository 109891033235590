import { CldImage as CloudinaryImage } from "next-cloudinary";
import type { CldImageProps as CloudinaryImageProps } from "next-cloudinary";
import type { BoxProps } from "../components/chakra-ui/box";
import type { ChakraComponent, HTMLChakraProps } from "@chakra-ui/react";
import { chakra, forwardRef, useTheme } from "@chakra-ui/react";
import type { ResponsiveValue } from "@chakra-ui/system";
import { useCallback, useMemo } from "react";
import type { ContentfulCloudinaryAssetField } from "./cld-types";
import { MotionBox } from "../components/project/motion-box";
import { animateProps } from "./animate-props";
import type { HTMLMotionProps } from "framer-motion";

export const getImageIsLandscape = (imageArr?: { width: number; height: number }[]): boolean => {
    if (!imageArr?.[0]) return false;
    const image = imageArr[0];
    return image.width > image.height;
};

type PureCldImageProps = Omit<CloudinaryImageProps, "sizes" | "src" | "alt" | "objectFit"> & {
    sizes?: ResponsiveValue<string>;
    cloudinaryAsset: ContentfulCloudinaryAssetField;
    wrapperProps?: BoxProps;
    motionWrapperProps?: BoxProps & HTMLMotionProps<"div">;
    alt?: string;
};

type CldImageProps = PureCldImageProps &
    Omit<HTMLChakraProps<"img">, keyof PureCldImageProps> & {
        fill?: boolean;
        animate?: boolean;
    };

const ChakraCloudinaryImage: ChakraComponent<
    "img",
    Omit<CldImageProps, "cloudinaryAsset">
> = chakra(CloudinaryImage, {
    shouldForwardProp: (prop) =>
        [
            "src",
            "alt",
            "width",
            "height",
            "fill",
            "loader",
            "quality",
            "priority",
            "loading",
            "placeholder",
            "blurDataURL",
            "unoptimized",
            "onLoadingComplete",
            "sizes",
            "gravity",
            "crop",
            "blur",
            "overflow",
            "rawTransformations",
        ].includes(prop),
});

ChakraCloudinaryImage.displayName = "ChakraCloudinaryImage";

function validateCloudinaryAsset(cloudinaryAsset: ContentfulCloudinaryAssetField) {
    if (!cloudinaryAsset) {
        throw new Error("CldImage: cloudinaryAsset is required");
    }

    if (Array.isArray(cloudinaryAsset)) {
        if (cloudinaryAsset.length === 0) {
            throw new Error("CldImage: cloudinaryAsset is required but array was empty");
        }

        if (cloudinaryAsset.length > 1) {
            throw new Error(
                "CldImage: cloudinaryAsset should be a single asset, not an array of assets - this needs to be configured in the Contentful Cloudinary App"
            );
        }
    }

    return cloudinaryAsset;
}

const createSizeString = (size: string, breakpoint: string, isLast: boolean) => {
    if (isLast) {
        return size;
    } else if (size) {
        return `(max-width: ${parseInt(breakpoint) - 1}px) ${size}`;
    }
};

const useParsedSizes = (sizes?: ResponsiveValue<string>) => {
    const { breakpoints }: { breakpoints: Record<string, string> } = useTheme();

    const parseSizes = useCallback(
        (inputSizes: ResponsiveValue<string>) => {
            const remainingBreakpoints = Object.values(breakpoints).slice(1);
            const parsedSizes: string[] = [];

            // Convert inputSizes to a unified format (array)
            const unifiedSizes = Array.isArray(inputSizes)
                ? inputSizes.map((size, index) => ({ size, index }))
                : Object.entries(inputSizes).map(([_, size], index) => ({ size, index }));

            unifiedSizes.forEach(({ size, index }) => {
                if (!size) return;
                const s = createSizeString(
                    size,
                    remainingBreakpoints[index],
                    index === unifiedSizes.length - 1
                );
                if (s) parsedSizes.push(s);
            });

            return parsedSizes.join(", ");
        },
        [breakpoints]
    );

    return useMemo(() => {
        if (!sizes) return;
        if (typeof sizes === "string") return sizes;
        return parseSizes(sizes);
    }, [sizes, parseSizes]);
};

const CldImage = forwardRef<CldImageProps, "img">((props, ref) => {
    const validCloudinaryAsset = validateCloudinaryAsset(props.cloudinaryAsset);

    const {
        // These are the props for Next Image
        alt,
        width,
        height,
        fill,
        loader,
        onError,
        quality,
        priority,
        loading,
        sizes,
        placeholder,
        blurDataURL,
        unoptimized,
        onLoadingComplete,
        objectFit,
        objectPosition,
        title,
        cloudinaryAsset,
        wrapperProps,
        crop,
        gravity,
        overflow,
        rawTransformations,
        animate = false,
        motionWrapperProps,
        ...rest
    } = props;

    const imageProps: Omit<CloudinaryImageProps, "src" | "alt"> = {
        width,
        height,
        quality,
        priority,
        loading,
        fill,
        onError,
        loader,
        onLoadingComplete,
        placeholder,
        blurDataURL,
        unoptimized,
        objectPosition,
        title,
    };

    const sizesString = useParsedSizes(sizes);

    // We will always have this property - though our type states otherwise - if not we throw an error
    const _src = validCloudinaryAsset[0].public_id;
    const _objectFit =
        (objectFit as "contain" | "cover" | "fill" | "none" | "scale-down") ?? "cover";
    const _alt = validCloudinaryAsset[0].context?.custom?.alt ?? alt ?? "";

    const _crop = crop ?? "thumb";
    const _gravity = gravity ?? "auto";

    const cldProps = {
        ...(rawTransformations
            ? {}
            : {
                  gravity: _gravity,
                  crop: _crop,
              }),
        rawTransformations,
    };

    if (!_src) {
        throw new Error("CldImage: Missing public_id in cloudinaryAsset");
    }

    return (
        <chakra.div
            borderRadius="large"
            __css={{
                position: "relative",
                overflow: overflow ?? "hidden",
                ...(width ? { width } : { width: "100%" }),
                ...(height ? { height } : { height: "100%" }),
            }}
            {...wrapperProps}
            ref={ref}
        >
            <MotionBox
                {...(animate && animateProps)}
                position="relative"
                width="100%"
                height="100%"
                {...motionWrapperProps}
            >
                <ChakraCloudinaryImage
                    {...rest}
                    {...imageProps}
                    {...(sizes && { sizes: sizesString })}
                    {...(sizes && !width && !height && { fill: true })}
                    {...cldProps}
                    alt={_alt}
                    objectFit={_objectFit}
                    src={_src}
                    ref={ref}
                    zIndex={0}
                />
            </MotionBox>
        </chakra.div>
    );
});

CldImage.displayName = "CldImage";

export { CldImage };
export type { CldImageProps };
