import type { HTMLChakraProps } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/react";
import { forwardRef, useMemo, useRef } from "react";
import { ChakraVideo } from "./chakra-video";
import type { ContentfulCloudinaryAssetField } from "../cld-types";
import { getCldVideoUrl, getPosterUrl } from "../utils";
import { useCldVideoUrl } from "./hooks";
import { useInView } from "framer-motion";

type CldVideoLiteProps = React.ComponentProps<typeof ChakraVideo> & {
    cloudinaryAsset: ContentfulCloudinaryAssetField;
    wrapperProps?: HTMLChakraProps<"div">;
    watchIsInView?: boolean;
};

const CldVideoLite = forwardRef<HTMLVideoElement, CldVideoLiteProps>((props, ref) => {
    const { wrapperProps, cloudinaryAsset, watchIsInView = true, ...rest } = props;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isInView = useInView(wrapperRef, { once: true });

    const videoUrl = useCldVideoUrl({
        cloudinaryAsset,
    });

    const posterUrl = useMemo(() => {
        return getPosterUrl(cloudinaryAsset);
    }, [cloudinaryAsset]);

    const ssrUrl = useMemo(() => {
        return getCldVideoUrl({
            src: cloudinaryAsset![0].public_id!,
        });
    }, [cloudinaryAsset]);

    let scr;
    let preload;

    if (!watchIsInView || !isInView) {
        scr = ssrUrl;
        preload = "none";
    } else {
        scr = videoUrl;
        preload = "auto";
    }

    return (
        <chakra.div
            borderRadius="large"
            ref={wrapperRef}
            __css={{ width: "100%", height: "100%", bg: "porscheBlack" }}
            {...wrapperProps}
        >
            <ChakraVideo
                {...rest}
                ref={ref}
                src={scr}
                preload={preload}
                autoPlay={!watchIsInView ? true : isInView}
                muted={true}
                loop={true}
                playsInline
                poster={posterUrl}
                objectFit="cover"
                position="absolute"
                zIndex={0}
                width="100%"
                height="100%"
                suppressHydrationWarning
            />
        </chakra.div>
    );
});

CldVideoLite.displayName = "CldVideoLite";

export { CldVideoLite };
