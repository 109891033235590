import React, { useMemo } from "react";
import type { HTMLChakraProps } from "@chakra-ui/react";
import { forwardRef, chakra } from "@chakra-ui/react";
import type { ResponsiveValue } from "@chakra-ui/system";
import type { StringOrNumber } from "@chakra-ui/utils";
import type { ResponsiveObject } from "@chakra-ui/styled-system";
import { useTheme } from "../../hooks/chakra-ui/chakra-ui";
import { customGetToken } from "../../hooks/chakra-ui/use-token";

interface AspectRatioOptions {
    ratio: ResponsiveValue<string>;
}

export interface AspectRatioProps extends HTMLChakraProps<"div">, AspectRatioOptions {
    children?: React.ReactNode;
}

export const aspectRatioDefaultProps: AspectRatioProps = {
    ratio: "4:3",
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { ratio: _, ...chakraDefaultProps } = aspectRatioDefaultProps;

export const AspectRatio = forwardRef<AspectRatioProps, "div">((props, ref) => {
    // Enforce single React Element child
    // const child = React.Children.only(props.children);

    const theme = useTheme();

    const ratio = useMemo(() => {
        if (Array.isArray(props.ratio)) {
            const fallbacks = (props.ratio as string[]).map(
                () => aspectRatioDefaultProps.ratio as StringOrNumber
            );

            return customGetToken("aspectRatios", props.ratio as string[], fallbacks)(theme);
        } else if (typeof props.ratio === "object") {
            const values = Object.values(props.ratio as ResponsiveObject<string>);

            const fallbacks = values.map(() => aspectRatioDefaultProps.ratio as StringOrNumber);

            return customGetToken("aspectRatios", values as string[], fallbacks)(theme);
        }

        return customGetToken(
            "aspectRatios",
            props.ratio,
            aspectRatioDefaultProps.ratio as string
        )(theme);
    }, [props.ratio, theme]);

    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const { ratio: _, children, overflow, ...rest } = props;

    return (
        <chakra.div
            ref={ref}
            {...chakraDefaultProps}
            {...rest}
            __css={{
                position: "relative",
                aspectRatio: ratio,
                "& > *:not(style)": {
                    overflow: overflow ?? "hidden",
                    position: "relative",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                },
            }}
        >
            {children}
        </chakra.div>
    );
});
