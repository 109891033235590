import type { MouseEventHandler } from "react";
import { useMemo } from "react";
import { useAppStore } from "@/store/app-store";
import { PorscheLogoRedDot } from "@/components/svgs/porsche-logo";
import type { BoxProps } from "@project/ui";
import { Box, NextLink } from "@project/ui";
import { trackingNavClick } from "@/components/contentful/main-navigation/tracking-nav-click";
import { useRouter } from "next/router";

const LOGO_BLACK = "#010205";
const LOGO_WHITE = "#ffffff";

type PorscheLogoProps = BoxProps & {
    onClick: MouseEventHandler<HTMLAnchorElement>;
    forceLogoColorWhite?: boolean;
};

const PorscheLogo = ({ onClick, forceLogoColorWhite = false, ...props }: PorscheLogoProps) => {
    const { locale, asPath } = useRouter();

    const {
        state: { pagePresentation, pageType, pageId },
    } = useAppStore();

    const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        const targetUrl = e.view.document.location.href ?? "/";
        const targetType = e.type;
        const clickElementId = pageId;
        const clickElementName = pageType;

        trackingNavClick({
            targetUrl,
            targetType,
            clickElementId,
            clickElementName,
            locale: locale!,
            asPath,
        });

        onClick && onClick(e);
    };

    const logoColor = useMemo(() => {
        if (forceLogoColorWhite === true) {
            return LOGO_WHITE;
        }

        if (pageType === "PageArticle") {
            if (pagePresentation === "split screen") {
                return LOGO_WHITE;
            }
            if (pagePresentation === "full screen") {
                return LOGO_BLACK;
            }
        }

        if (pageType === "PageBasic") {
            if (pagePresentation === "basic-with-image") {
                return LOGO_WHITE;
            }
            if (pagePresentation === "basic-without-image") {
                return LOGO_BLACK;
            }
        }

        if (pageType == "PageCategory" || pageType == "PageTeam") {
            return LOGO_BLACK;
        } else {
            return LOGO_WHITE;
        }
    }, [pagePresentation, pageType, forceLogoColorWhite]);

    return (
        <Box position="relative" width="142px" height="28px" {...props}>
            <NextLink
                href="/"
                aria-label="Home"
                display="inline-block"
                position="absolute"
                onClick={handleOnClick}
            >
                <PorscheLogoRedDot width="142px" height="28px" color={logoColor} />
            </NextLink>
        </Box>
    );
};

export { PorscheLogo };
