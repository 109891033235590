import type { GridProps, GridItemProps } from "@project/ui";
import { Grid, GridItem, forwardRef } from "@project/ui";

type SectionGridItemProps = GridItemProps;

const SectionGridItem = forwardRef<SectionGridItemProps, "div">(
    ({ children, ...gridItemProps }, ref) => {
        return (
            <GridItem {...gridItemProps} ref={ref}>
                {children}
            </GridItem>
        );
    }
);

type SectionGridProps = GridProps;

const SectionGrid = forwardRef<SectionGridProps, "div">(({ children, ...gridProps }, ref) => {
    return (
        <Grid
            templateColumns={{ base: "repeat(1, 1fr)", s: "repeat(2, 1fr)" }}
            templateRows="repeat(1fr)"
            columnGap={6}
            rowGap={3}
            {...gridProps}
            ref={ref}
        >
            {children}
        </Grid>
    );
});

export { SectionGrid, SectionGridItem };
