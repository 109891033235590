import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartCircuitTimelineItem_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ContentTagFieldsFragment } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { DriverFieldsFragment } from "../../driver/__generated/driver.contentful.generated";
import {
    PartnerSetFieldsFragment,
    PartnerFieldsFragment,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ContentTagFieldsFragmentDoc } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { DriverFieldsFragmentDoc } from "../../driver/__generated/driver.contentful.generated";
import {
    PartnerSetFieldsFragmentDoc,
    PartnerFieldsFragmentDoc,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type PageDriverLinkToFieldsFragment = {
    __typename?: "PageDriver";
    title?: string | null;
    linkTitle?: string | null;
    slug?: string | null;
    tagsCollection?: {
        __typename?: "PageDriverTagsCollection";
        items: Array<({ __typename?: "ContentTag" } & ContentTagFieldsFragment) | null>;
    } | null;
    driver?:
        | ({
              __typename?: "Driver";
              asset?: any | null;
              flag?: string | null;
              driverName?: string | null;
          } & ComponentReferenceFields_Driver_Fragment)
        | null;
} & ComponentReferenceFields_PageDriver_Fragment;

export type PageDriverFieldsFragment = {
    __typename?: "PageDriver";
    introductionCaption?: string | null;
    introduction?: string | null;
    introHeading?: string | null;
    introColumn1?: string | null;
    introColumn2?: string | null;
    seoMetaDescription?: string | null;
    robotFollow?: string | null;
    robotIndex?: string | null;
    driver?: ({ __typename?: "Driver" } & DriverFieldsFragment) | null;
    modulesCollection?: {
        __typename: "PageDriverModulesCollection";
        items: Array<
            | { __typename: "ModuleAccordion"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarTechSpecs"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarousel"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCircuit"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleDriverList"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleGallery"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleImage"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleMediaFeature"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModulePageLinkTile"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleQuote"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleRichText"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSideBySide"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSpacer"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleTable"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleVideo"; sys: { __typename?: "Sys"; id: string } }
            | null
        >;
    } | null;
    partnerSet?: ({ __typename?: "PartnerSet" } & PartnerSetFieldsFragment) | null;
} & PageDriverLinkToFieldsFragment;

export type PageDriverCollectionQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    slug: Types.Scalars["String"]["input"];
}>;

export type PageDriverCollectionQuery = {
    __typename?: "Query";
    pageDriverCollection?: {
        __typename?: "PageDriverCollection";
        items: Array<({ __typename?: "PageDriver" } & PageDriverFieldsFragment) | null>;
    } | null;
};

export type PageDriverCollectionByIdsQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    ids: Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"];
    limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type PageDriverCollectionByIdsQuery = {
    __typename?: "Query";
    pageDriverCollection?: {
        __typename?: "PageDriverCollection";
        items: Array<({ __typename?: "PageDriver" } & PageDriverFieldsFragment) | null>;
    } | null;
};

export type PageDriverQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    id: Types.Scalars["String"]["input"];
}>;

export type PageDriverQuery = {
    __typename?: "Query";
    page?: ({ __typename?: "PageDriver" } & PageDriverFieldsFragment) | null;
};

export const PageDriverLinkToFieldsFragmentDoc = `
    fragment PageDriverLinkToFields on PageDriver {
  ...ComponentReferenceFields
  title
  linkTitle
  slug
  tagsCollection {
    items {
      ...ContentTagFields
    }
  }
  driver {
    ...ComponentReferenceFields
    asset
    flag
    driverName
  }
}
    `;
export const PageDriverFieldsFragmentDoc = `
    fragment PageDriverFields on PageDriver {
  ...PageDriverLinkToFields
  introductionCaption
  introduction
  introHeading
  introColumn1
  introColumn2
  driver {
    ...DriverFields
  }
  modulesCollection(limit: 20) {
    __typename
    items {
      __typename
      ... on Entry {
        sys {
          id
        }
      }
    }
  }
  partnerSet {
    ...PartnerSetFields
  }
  seoMetaDescription
  robotFollow
  robotIndex
}
    `;
export const PageDriverCollectionDocument = `
    query PageDriverCollection($locale: String!, $preview: Boolean, $slug: String!) {
  pageDriverCollection(
    limit: 1
    locale: $locale
    preview: $preview
    where: {slug: $slug, title_exists: true}
  ) {
    items {
      ...PageDriverFields
    }
  }
}
    ${PageDriverFieldsFragmentDoc}
${PageDriverLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${DriverFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageDriverCollectionQuery = <TData = PageDriverCollectionQuery, TError = unknown>(
    variables: PageDriverCollectionQueryVariables,
    options?: Omit<UseQueryOptions<PageDriverCollectionQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageDriverCollectionQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageDriverCollectionQuery, TError, TData>({
        queryKey: ["PageDriverCollection", variables],
        queryFn: customFetcher<PageDriverCollectionQuery, PageDriverCollectionQueryVariables>(
            PageDriverCollectionDocument,
            variables
        ),
        ...options,
    });
};

usePageDriverCollectionQuery.getKey = (variables: PageDriverCollectionQueryVariables) => [
    "PageDriverCollection",
    variables,
];

usePageDriverCollectionQuery.fetcher = (
    variables: PageDriverCollectionQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageDriverCollectionQuery, PageDriverCollectionQueryVariables>(
        PageDriverCollectionDocument,
        variables,
        options
    );

export const PageDriverCollectionByIdsDocument = `
    query PageDriverCollectionByIds($locale: String!, $preview: Boolean, $ids: [String!]!, $limit: Int = 20) {
  pageDriverCollection(
    locale: $locale
    preview: $preview
    limit: $limit
    where: {sys: {id_in: $ids}}
  ) {
    items {
      ...PageDriverFields
    }
  }
}
    ${PageDriverFieldsFragmentDoc}
${PageDriverLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${DriverFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageDriverCollectionByIdsQuery = <
    TData = PageDriverCollectionByIdsQuery,
    TError = unknown,
>(
    variables: PageDriverCollectionByIdsQueryVariables,
    options?: Omit<UseQueryOptions<PageDriverCollectionByIdsQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageDriverCollectionByIdsQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageDriverCollectionByIdsQuery, TError, TData>({
        queryKey: ["PageDriverCollectionByIds", variables],
        queryFn: customFetcher<
            PageDriverCollectionByIdsQuery,
            PageDriverCollectionByIdsQueryVariables
        >(PageDriverCollectionByIdsDocument, variables),
        ...options,
    });
};

usePageDriverCollectionByIdsQuery.getKey = (variables: PageDriverCollectionByIdsQueryVariables) => [
    "PageDriverCollectionByIds",
    variables,
];

usePageDriverCollectionByIdsQuery.fetcher = (
    variables: PageDriverCollectionByIdsQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageDriverCollectionByIdsQuery, PageDriverCollectionByIdsQueryVariables>(
        PageDriverCollectionByIdsDocument,
        variables,
        options
    );

export const PageDriverDocument = `
    query PageDriver($locale: String!, $preview: Boolean, $id: String!) {
  page: pageDriver(id: $id, locale: $locale, preview: $preview) {
    ...PageDriverFields
  }
}
    ${PageDriverFieldsFragmentDoc}
${PageDriverLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${DriverFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageDriverQuery = <TData = PageDriverQuery, TError = unknown>(
    variables: PageDriverQueryVariables,
    options?: Omit<UseQueryOptions<PageDriverQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageDriverQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageDriverQuery, TError, TData>({
        queryKey: ["PageDriver", variables],
        queryFn: customFetcher<PageDriverQuery, PageDriverQueryVariables>(
            PageDriverDocument,
            variables
        ),
        ...options,
    });
};

usePageDriverQuery.getKey = (variables: PageDriverQueryVariables) => ["PageDriver", variables];

usePageDriverQuery.fetcher = (
    variables: PageDriverQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageDriverQuery, PageDriverQueryVariables>(
        PageDriverDocument,
        variables,
        options
    );
