import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartCircuitTimelineItem_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import {
    PartnerSetFieldsFragment,
    PartnerFieldsFragment,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { ContentTagFieldsFragment } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { CarFieldsFragment } from "../../car/__generated/car.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import {
    PartnerSetFieldsFragmentDoc,
    PartnerFieldsFragmentDoc,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { ContentTagFieldsFragmentDoc } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { CarFieldsFragmentDoc } from "../../car/__generated/car.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type PageCarLinkToFieldsFragment = {
    __typename?: "PageCar";
    title?: string | null;
    linkTitle?: string | null;
    slug?: string | null;
    heroAsset?: any | null;
    linkLabel?: string | null;
    link?:
        | ({
              __typename?: "PageArticle";
              title?: string | null;
              linkTitle?: string | null;
              slug?: string | null;
          } & ComponentReferenceFields_PageArticle_Fragment)
        | ({
              __typename?: "PageBasic";
              title?: string | null;
              linkTitle?: string | null;
              slug?: string | null;
          } & ComponentReferenceFields_PageBasic_Fragment)
        | ({
              __typename?: "PageCar";
              title?: string | null;
              linkTitle?: string | null;
              slug?: string | null;
          } & ComponentReferenceFields_PageCar_Fragment)
        | ({
              __typename?: "PageCategory";
              title?: string | null;
              linkTitle?: string | null;
              mainCategory?: string | null;
          } & ComponentReferenceFields_PageCategory_Fragment)
        | ({
              __typename?: "PageDriver";
              title?: string | null;
              linkTitle?: string | null;
              slug?: string | null;
          } & ComponentReferenceFields_PageDriver_Fragment)
        | ({
              __typename?: "PageHomepage";
              title?: string | null;
              linkTitle?: string | null;
          } & ComponentReferenceFields_PageHomepage_Fragment)
        | ({
              __typename?: "PageRaceEvent";
              title?: string | null;
              linkTitle?: string | null;
              slug?: string | null;
          } & ComponentReferenceFields_PageRaceEvent_Fragment)
        | ({
              __typename?: "PageRaceSeries";
              title?: string | null;
              linkTitle?: string | null;
              slug?: string | null;
          } & ComponentReferenceFields_PageRaceSeries_Fragment)
        | ({
              __typename?: "PageSearch";
              title?: string | null;
              linkTitle?: string | null;
          } & ComponentReferenceFields_PageSearch_Fragment)
        | ({
              __typename?: "PageTeam";
              title?: string | null;
              linkTitle?: string | null;
              slug?: string | null;
          } & ComponentReferenceFields_PageTeam_Fragment)
        | null;
    partnerSet?: ({ __typename?: "PartnerSet" } & PartnerSetFieldsFragment) | null;
    tagsCollection?: {
        __typename?: "PageCarTagsCollection";
        items: Array<({ __typename?: "ContentTag" } & ContentTagFieldsFragment) | null>;
    } | null;
    car?:
        | ({ __typename?: "Car"; asset?: any | null } & ComponentReferenceFields_Car_Fragment)
        | null;
} & ComponentReferenceFields_PageCar_Fragment;

export type PageCarFieldsFragment = {
    __typename?: "PageCar";
    subtitle?: string | null;
    heroAsset?: any | null;
    introductionCaption?: string | null;
    introduction?: string | null;
    introHeading?: string | null;
    introColumn1?: string | null;
    introColumn2?: string | null;
    seoMetaDescription?: string | null;
    robotFollow?: string | null;
    robotIndex?: string | null;
    car?: ({ __typename?: "Car" } & CarFieldsFragment) | null;
    modulesCollection?: {
        __typename: "PageCarModulesCollection";
        items: Array<
            | { __typename: "ModuleAccordion"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarTechSpecs"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarousel"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCircuit"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleDriverList"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleGallery"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleImage"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleListenToTheEngine"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleMediaFeature"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModulePageLinkTile"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleQuote"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleRichText"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSideBySide"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSpacer"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSplitLayout"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleTable"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleVideo"; sys: { __typename?: "Sys"; id: string } }
            | null
        >;
    } | null;
} & PageCarLinkToFieldsFragment;

export type PageCarCollectionQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    slug: Types.Scalars["String"]["input"];
}>;

export type PageCarCollectionQuery = {
    __typename?: "Query";
    pageCarCollection?: {
        __typename?: "PageCarCollection";
        items: Array<({ __typename?: "PageCar" } & PageCarFieldsFragment) | null>;
    } | null;
};

export type PageCarQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    id: Types.Scalars["String"]["input"];
}>;

export type PageCarQuery = {
    __typename?: "Query";
    page?: ({ __typename?: "PageCar" } & PageCarFieldsFragment) | null;
};

export const PageCarLinkToFieldsFragmentDoc = `
    fragment PageCarLinkToFields on PageCar {
  ...ComponentReferenceFields
  title
  linkTitle
  slug
  heroAsset
  linkLabel
  link {
    ... on PageArticle {
      ...ComponentReferenceFields
      title
      linkTitle
      slug
    }
    ... on PageBasic {
      ...ComponentReferenceFields
      title
      linkTitle
      slug
    }
    ... on PageCar {
      ...ComponentReferenceFields
      title
      linkTitle
      slug
    }
    ... on PageCategory {
      ...ComponentReferenceFields
      title
      linkTitle
      mainCategory
    }
    ... on PageDriver {
      ...ComponentReferenceFields
      title
      linkTitle
      slug
    }
    ... on PageHomepage {
      ...ComponentReferenceFields
      title
      linkTitle
    }
    ... on PageRaceSeries {
      ...ComponentReferenceFields
      title
      linkTitle
      slug
    }
    ... on PageRaceEvent {
      ...ComponentReferenceFields
      title
      linkTitle
      slug
    }
    ... on PageSearch {
      ...ComponentReferenceFields
      title
      linkTitle
    }
    ... on PageTeam {
      ...ComponentReferenceFields
      title
      linkTitle
      slug
    }
  }
  partnerSet {
    ...PartnerSetFields
  }
  tagsCollection {
    items {
      ...ContentTagFields
    }
  }
  car {
    ...ComponentReferenceFields
    asset
  }
}
    `;
export const PageCarFieldsFragmentDoc = `
    fragment PageCarFields on PageCar {
  ...PageCarLinkToFields
  car {
    ...CarFields
  }
  subtitle
  heroAsset
  introductionCaption
  introduction
  introHeading
  introColumn1
  introColumn2
  modulesCollection(limit: 20) {
    __typename
    items {
      __typename
      ... on Entry {
        sys {
          id
        }
      }
    }
  }
  seoMetaDescription
  robotFollow
  robotIndex
}
    `;
export const PageCarCollectionDocument = `
    query PageCarCollection($locale: String!, $preview: Boolean, $slug: String!) {
  pageCarCollection(
    limit: 1
    locale: $locale
    preview: $preview
    where: {slug: $slug}
  ) {
    items {
      ...PageCarFields
    }
  }
}
    ${PageCarFieldsFragmentDoc}
${PageCarLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${CarFieldsFragmentDoc}`;

export const usePageCarCollectionQuery = <TData = PageCarCollectionQuery, TError = unknown>(
    variables: PageCarCollectionQueryVariables,
    options?: Omit<UseQueryOptions<PageCarCollectionQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageCarCollectionQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageCarCollectionQuery, TError, TData>({
        queryKey: ["PageCarCollection", variables],
        queryFn: customFetcher<PageCarCollectionQuery, PageCarCollectionQueryVariables>(
            PageCarCollectionDocument,
            variables
        ),
        ...options,
    });
};

usePageCarCollectionQuery.getKey = (variables: PageCarCollectionQueryVariables) => [
    "PageCarCollection",
    variables,
];

usePageCarCollectionQuery.fetcher = (
    variables: PageCarCollectionQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageCarCollectionQuery, PageCarCollectionQueryVariables>(
        PageCarCollectionDocument,
        variables,
        options
    );

export const PageCarDocument = `
    query PageCar($locale: String!, $preview: Boolean, $id: String!) {
  page: pageCar(id: $id, locale: $locale, preview: $preview) {
    ...PageCarFields
  }
}
    ${PageCarFieldsFragmentDoc}
${PageCarLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${CarFieldsFragmentDoc}`;

export const usePageCarQuery = <TData = PageCarQuery, TError = unknown>(
    variables: PageCarQueryVariables,
    options?: Omit<UseQueryOptions<PageCarQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageCarQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageCarQuery, TError, TData>({
        queryKey: ["PageCar", variables],
        queryFn: customFetcher<PageCarQuery, PageCarQueryVariables>(PageCarDocument, variables),
        ...options,
    });
};

usePageCarQuery.getKey = (variables: PageCarQueryVariables) => ["PageCar", variables];

usePageCarQuery.fetcher = (variables: PageCarQueryVariables, options?: RequestInit["headers"]) =>
    customFetcher<PageCarQuery, PageCarQueryVariables>(PageCarDocument, variables, options);
