import type { ModulePageLinkTileFieldsFragment } from "@/components/contentful/module-page-link-tile/__generated/module-page-link-tile.contentful.generated";
import { Box, SimpleGrid } from "@project/ui";
import { GenericPageCard } from "@/components/generic-page-card";
import { ModuleSpacer } from "@/components/module-spacer";
import { ModuleHeader } from "@/components/module-header";

type ModulePageLinkTileProps = ModulePageLinkTileFieldsFragment;

const ModulePageLinkTile = (props: ModulePageLinkTileProps) => {
    const { title, description, pagesCollection } = props;

    return (
        <ModuleSpacer>
            <ModuleHeader mb={9} title={title} description={description} />

            {pagesCollection?.items.length && (
                <SimpleGrid
                    columns={{
                        base: 1,
                        l: pagesCollection.items.length,
                    }}
                    spacing={{
                        base: 4,
                        l: 8,
                    }}
                >
                    {pagesCollection.items.map(
                        (page) =>
                            page?.__typename && (
                                <Box
                                    key={crypto.randomUUID()}
                                    mb={{
                                        base: 6,
                                        l: 0,
                                    }}
                                >
                                    <GenericPageCard item={page} />
                                </Box>
                            )
                    )}
                </SimpleGrid>
            )}
        </ModuleSpacer>
    );
};

export { ModulePageLinkTile };
