import { PButtonPure } from "@porsche-design-system/components-react/ssr";
import type { PButtonPureProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type ButtonPureProps = PButtonPureProps & BoxProps;

const ButtonPure = chakra<typeof PButtonPure, PButtonPureProps>(PButtonPure);

export { ButtonPure };
export type { ButtonPureProps };
