import trackGTMEvent from "@/components/google-tag-manager-tracking/gtmUtils";

type TrackingNavClickArgs = {
    targetUrl: string;
    targetType: string;
    clickElementId: string | undefined;
    clickElementName: string | undefined;
    locale: string;
    asPath: string;
};

export const trackingNavClick = ({
    targetUrl,
    targetType,
    clickElementId,
    clickElementName,
    locale,
    asPath,
}: TrackingNavClickArgs) =>
    trackGTMEvent({
        customEventAction: "PAGMSH_NavigationLink_Click",
        componentClick: true,
        targetUrl: targetUrl,
        targetType: targetType,
        clickElementId: clickElementId,
        clickElementName: clickElementName,
        locale,
        asPath,
    });
