import {
    breakpointL,
    breakpointM,
    breakpointS,
    breakpointXL,
    breakpointXS,
    breakpointXXL,
} from "@porsche-design-system/components-react/styles";

export const mediaQueryMinWidth = {
    xs: `@media (min-width: ${breakpointXS}px)`,
    s: `@media (min-width: ${breakpointS}px)`,
    md: `@media (min-width: ${breakpointM}px)`,
    l: `@media (min-width: ${breakpointL}px)`,
    xl: `@media (min-width: ${breakpointXL}px)`,
    xxl: `@media (min-width: ${breakpointXXL}px)`,
} as const;

export const mediaQueryMaxWidth = {
    xs: `@media (max-width: ${breakpointXS - 1}px)`,
    s: `@media (max-width: ${breakpointS - 1}px)`,
    md: `@media (max-width: ${breakpointM - 1}px)`,
    l: `@media (max-width: ${breakpointL - 1}px)`,
    xl: `@media (max-width: ${breakpointXL - 1}px)`,
    xxl: `@media (max-width: ${breakpointXXL - 1}px)`,
} as const;
