import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartCircuitTimelineItem_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
export type DriverFieldsFragment = {
    __typename?: "Driver";
    driverName?: string | null;
    asset?: any | null;
    driverNumber?: string | null;
    dateOfBirth?: any | null;
    flag?: string | null;
    country?: string | null;
    placeOfBirth?: string | null;
    height?: string | null;
    weight?: string | null;
    series?: string | null;
} & ComponentReferenceFields_Driver_Fragment;

export const DriverFieldsFragmentDoc = `
    fragment DriverFields on Driver {
  ...ComponentReferenceFields
  driverName
  asset
  driverNumber
  dateOfBirth
  flag
  country
  placeOfBirth
  height
  weight
  series
}
    `;
