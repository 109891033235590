import type { BoxProps } from "@project/ui";
import {
    Box,
    CldImage,
    CldVideo,
    CldVideoPlayerProvider,
    hasCloudinaryAsset,
    isCloudinaryVideo,
} from "@project/ui";
import { WrapperContainer } from "@/components/wrapper-container";
import { LinkWrapper } from "@/components/link-wrapper";
import { motion, useAnimation, useInView, useScroll, useTransform } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { TemporaryPlayPauseButton } from "@/components/temporary-play-pause-button";
import { Title } from "@/components/title";
import { Description } from "@/components/description";
import type { ModuleMediaFeatureFieldsFragment } from "./contentful/module-media-feature/__generated/module-media-feature.contentful.generated";

export type MediaFeatureProps = Omit<ModuleMediaFeatureFieldsFragment, "__typename" | "sys"> & {
    containerProps?: BoxProps;
};

export const MediaFeature = (props: MediaFeatureProps) => {
    const { title, text, mediaAsset, link, linkLabel, containerProps } = props;

    const textControls = useAnimation();

    const imgRef = useRef(null);
    const textRef = useRef(null);
    const isTextInView = useInView(textRef, { once: true });
    const [hasAnimated, setHasAnimated] = useState(false);

    const { scrollYProgress } = useScroll({
        target: imgRef,
        offset: ["center center", "0 90vh"],
    });

    const scale = useTransform(scrollYProgress, [0.5, 1], [1, 0.8]);

    useEffect(() => {
        if (isTextInView && !hasAnimated) {
            textControls.start("visible");
            setHasAnimated(true);
        }
    }, [textControls, isTextInView, hasAnimated]);

    return (
        <Box as={motion.div} ref={imgRef} style={{ scale, overflow: "hidden" }}>
            <Box position="relative" w="100vw" h="100svh" {...containerProps}>
                {hasCloudinaryAsset(mediaAsset) && (
                    <>
                        {isCloudinaryVideo(mediaAsset) ? (
                            <CldVideoPlayerProvider autoplay muted loop>
                                <CldVideo
                                    cloudinaryAsset={mediaAsset}
                                    wrapperProps={{ position: "absolute", borderRadius: "unset" }}
                                    loop
                                />
                                <TemporaryPlayPauseButton />
                            </CldVideoPlayerProvider>
                        ) : (
                            <CldImage
                                cloudinaryAsset={mediaAsset}
                                priority={true}
                                fill
                                wrapperProps={{ position: "absolute", borderRadius: "unset" }}
                            />
                        )}
                    </>
                )}
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    minHeight="100%"
                    opacity={0.7}
                    bgGradient="linear-gradient(to top, transparent, porscheBlack)"
                    zIndex={1}
                />
                <Box
                    position="absolute"
                    top="30vh"
                    left="0"
                    height="50%"
                    minHeight="100%"
                    zIndex={1}
                    ref={textRef}
                />
                <Box position={"absolute"} top="0" left="0" zIndex={2} width="100%">
                    <WrapperContainer>
                        <motion.div
                            animate={textControls}
                            initial="hidden"
                            variants={{
                                visible: { opacity: 1, y: 0 },
                                hidden: { opacity: 0, y: 20 },
                            }}
                            transition={{ duration: 1, ease: "anticipate", delay: 0.3 }}
                        >
                            <Box my={20} color={"allWhite"} maxW="40rem">
                                {title && <Title>{title}</Title>}
                                {text && <Description mt={8}>{text}</Description>}
                                {link && (
                                    <Box mt={8}>
                                        <LinkWrapper item={link} theme="dark">
                                            {linkLabel}
                                        </LinkWrapper>
                                    </Box>
                                )}
                            </Box>
                        </motion.div>
                    </WrapperContainer>
                </Box>
            </Box>
        </Box>
    );
};
