import { Box, Grid, GridItem, Text, List, ListItem } from "@project/ui";
import type { FooterFieldsFragment } from "@/lib/contentful/__generated/graphql.types";
import { LinkWrapper } from "@/components/link-wrapper";
import type { LinkWrapperProps } from "@/components/link-wrapper";
import { useMemo } from "react";
import { richTextRenderer } from "@/lib/contentful/rich-text-renderer/rich-text-renderer";
import { useRouter } from "next/router";
import { ModuleSpacer } from "@/components/module-spacer";
import trackGTMEvent from "@/components/google-tag-manager-tracking/gtmUtils";

type FooterProps = FooterFieldsFragment;

type FooterTextLinkProps = LinkWrapperProps & {
    trackingFooterClick: (
        targetUrl: string,
        targetType: string,
        clickElementId: string | undefined,
        clickElementName: string | undefined
    ) => void;
};

const FooterTextLink = ({ onClick, trackingFooterClick, ...props }: FooterTextLinkProps) => {
    return (
        <LinkWrapper
            {...props}
            onClick={(e) => {
                onClick && onClick(e);
                const targetUrl = e.view.document.location.href;
                const targetType = e.type;
                const clickElementId = props.item?.sys?.id;
                const clickElementName = props.item.__typename;
                trackingFooterClick(targetUrl, targetType, clickElementId, clickElementName);
            }}
            theme="dark"
            icon="none"
        />
    );
};

export const Footer = (props: FooterProps) => {
    const quaternaryNavigation = props.quaternaryNavigation;

    const {
        copyrightText,
        legalDisclaimer,
        primaryNavigation,
        secondaryNavigation,
        tertiaryNavigation,
    } = useMemo(() => props, [props]);

    const { locale, asPath } = useRouter();

    const trackingFooterClick = (
        targetUrl: string,
        targetType: string,
        clickElementId: string | undefined,
        clickElementName: string | undefined
    ) => {
        trackGTMEvent({
            customEventAction: "PAGMSH_FooterLink_Click",
            componentClick: true,
            targetUrl: targetUrl,
            targetType: targetType,
            clickElementId: clickElementId,
            clickElementName: clickElementName,
            locale: locale,
            asPath: asPath,
        });
    };

    const currentYear = new Date().getFullYear().toString();
    const updatedCopyrightText = (copyrightText ?? "").replace("{year}", currentYear);

    return (
        <ModuleSpacer as="footer" bg="porscheBlack" color="allWhite" pb={10} py={0}>
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    s: "repeat(2, 1fr)",
                    md: "repeat(4, 1fr)",
                }}
                gap={{ base: 12, l: 8 }}
                py={10}
            >
                <GridItem>
                    <List>
                        {primaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="2">
                                        <FooterTextLink
                                            size="x-large"
                                            item={item}
                                            trackingFooterClick={trackingFooterClick}
                                        />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
                <GridItem>
                    <Text size="caption" mb={4} color="grey600">
                        {secondaryNavigation?.title}
                    </Text>
                    <List>
                        {secondaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="1">
                                        <FooterTextLink
                                            item={item}
                                            trackingFooterClick={trackingFooterClick}
                                        />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
                <GridItem>
                    <Text size="caption" mb={4} color="grey600">
                        {tertiaryNavigation?.title}
                    </Text>
                    <List>
                        {tertiaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="1">
                                        <FooterTextLink
                                            item={item}
                                            trackingFooterClick={trackingFooterClick}
                                        />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
                <GridItem>
                    <Text size="caption" mb={4} color="grey600">
                        {quaternaryNavigation?.title}
                    </Text>
                    <List>
                        {quaternaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="1">
                                        <FooterTextLink
                                            item={item}
                                            trackingFooterClick={trackingFooterClick}
                                        />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
            </Grid>
            <Text size="small" mb={4}>
                {updatedCopyrightText}
            </Text>
            {legalDisclaimer && (
                <Box className="rte" fontSize="xx-small">
                    {richTextRenderer(legalDisclaimer)}
                </Box>
            )}
        </ModuleSpacer>
    );
};
