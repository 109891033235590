import { Box, Flex, Text, useDisclosure, useMediaQuery, forwardRef } from "@project/ui";
import type { BoxProps } from "@project/ui";
import type { MainNavigationFieldsFragment } from "@/components/contentful/main-navigation/__generated/main-navigation.contentful.generated";
import { TwitchModal } from "@/components/modals/twitch";
import { QuickLink } from "@/components/contentful/main-navigation/components/quick-link";
import { frostedGlassStyle } from "@porsche-design-system/components-react/styles";
import type { LinkWrapperProps } from "@/components/link-wrapper";

const HighlightLinkButton = forwardRef<BoxProps, "button">(({ children, ...props }, ref) => {
    return (
        <Flex
            as="button"
            color="allWhite"
            backdropFilter={frostedGlassStyle.backdropFilter}
            backgroundColor="porscheBlackShaded"
            px={5}
            height={{ base: 9, l: "navQuickLinksHeight" }}
            borderRadius="medium"
            transition="var(--transition-property-common), var(--transition-duration-short) var(--transition-easing-base)"
            alignItems="center"
            justifyContent="center"
            gap={2}
            {...props}
            ref={ref}
        >
            <Box as="span" bgColor="motorsportsRed" height={2} width={2} borderRadius="full" />
            <Text>{children}</Text>
        </Flex>
    );
});

type HighlightLinkProps = Omit<LinkWrapperProps, "item"> &
    Pick<MainNavigationFieldsFragment, "highlightLink">;

const HighlightLink = forwardRef<HighlightLinkProps, "button">(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ({ highlightLink, ...props }, ref) => {
        const { isOpen, onOpen, onClose } = useDisclosure();

        const [isMouseDevice] = useMediaQuery("(hover: hover)", {
            ssr: true,
            fallback: true,
        });

        const handleOnOpen = () => {
            if (!isMouseDevice) {
                if (
                    highlightLink &&
                    highlightLink.__typename === "ModalLink" &&
                    highlightLink.embedId &&
                    highlightLink.embedProvider === "twitch"
                ) {
                    const targetUrl = [
                        "https://www.twitch.tv",
                        ...(highlightLink.twitchEmbedType === "channel"
                            ? [highlightLink.embedId]
                            : []),
                        ...(highlightLink.twitchEmbedType === "videos"
                            ? ["videos", highlightLink.embedId]
                            : []),
                        ...(highlightLink.twitchEmbedType === "collections"
                            ? ["collections", highlightLink.embedId]
                            : []),
                    ].join("/");

                    window.open(targetUrl, "_blank");
                }
                return;
            }
            onOpen();
        };

        if (highlightLink?.__typename === "ModalLink" && highlightLink.embedId) {
            return (
                <>
                    <HighlightLinkButton {...props} onClick={handleOnOpen} ref={ref}>
                        {highlightLink.label}
                    </HighlightLinkButton>
                    <TwitchModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onOpen={onOpen}
                        embedId={highlightLink.embedId}
                        title={highlightLink.modalTitle}
                        twitchEmbedType={
                            highlightLink.twitchEmbedType as "channel" | "video" | "collection"
                        }
                    />
                </>
            );
        }

        if (highlightLink && highlightLink?.__typename !== "ModalLink") {
            return <QuickLink {...props} item={highlightLink} />;
        }

        return null;
    }
);

export { HighlightLink };
