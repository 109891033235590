import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartCircuitTimelineItem_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import {
    PartnerSetFieldsFragment,
    PartnerFieldsFragment,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import {
    PartnerSetFieldsFragmentDoc,
    PartnerFieldsFragmentDoc,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type PageCategoryLinkToFieldsFragment = {
    __typename?: "PageCategory";
    title?: string | null;
    linkTitle?: string | null;
    mainCategory?: string | null;
    heroAsset?: any | null;
    subtitle?: string | null;
    introductionCaption?: string | null;
    introduction?: string | null;
} & ComponentReferenceFields_PageCategory_Fragment;

export type PageCategoryFieldsFragment = {
    __typename?: "PageCategory";
    seoMetaDescription?: string | null;
    robotFollow?: string | null;
    robotIndex?: string | null;
    modulesCollection?: {
        __typename: "PageCategoryModulesCollection";
        items: Array<
            | { __typename: "ModuleAccordion"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarTechSpecs"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarousel"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCircuit"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleDriverList"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleGallery"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleImage"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleMediaFeature"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModulePageLinkTile"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleQuote"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleRichText"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSideBySide"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSpacer"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleTable"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleVideo"; sys: { __typename?: "Sys"; id: string } }
            | null
        >;
    } | null;
    partnerSet?: ({ __typename?: "PartnerSet" } & PartnerSetFieldsFragment) | null;
} & PageCategoryLinkToFieldsFragment;

export type PageCategoryCollectionQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    mainCategory: Types.Scalars["String"]["input"];
}>;

export type PageCategoryCollectionQuery = {
    __typename?: "Query";
    pageCategoryCollection?: {
        __typename?: "PageCategoryCollection";
        items: Array<({ __typename?: "PageCategory" } & PageCategoryFieldsFragment) | null>;
    } | null;
};

export type PageCategoryQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    id: Types.Scalars["String"]["input"];
}>;

export type PageCategoryQuery = {
    __typename?: "Query";
    page?: ({ __typename?: "PageCategory" } & PageCategoryFieldsFragment) | null;
};

export const PageCategoryLinkToFieldsFragmentDoc = `
    fragment PageCategoryLinkToFields on PageCategory {
  ...ComponentReferenceFields
  title
  linkTitle
  mainCategory
  heroAsset
  subtitle
  introductionCaption
  introduction
  mainCategory
}
    `;
export const PageCategoryFieldsFragmentDoc = `
    fragment PageCategoryFields on PageCategory {
  ...PageCategoryLinkToFields
  modulesCollection(limit: 20) {
    __typename
    items {
      __typename
      ... on Entry {
        sys {
          id
        }
      }
    }
  }
  partnerSet {
    ...PartnerSetFields
  }
  seoMetaDescription
  robotFollow
  robotIndex
}
    `;
export const PageCategoryCollectionDocument = `
    query PageCategoryCollection($locale: String!, $preview: Boolean, $mainCategory: String!) {
  pageCategoryCollection(
    limit: 1
    locale: $locale
    preview: $preview
    where: {mainCategory: $mainCategory, title_exists: true}
  ) {
    items {
      ...PageCategoryFields
    }
  }
}
    ${PageCategoryFieldsFragmentDoc}
${PageCategoryLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageCategoryCollectionQuery = <
    TData = PageCategoryCollectionQuery,
    TError = unknown,
>(
    variables: PageCategoryCollectionQueryVariables,
    options?: Omit<UseQueryOptions<PageCategoryCollectionQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageCategoryCollectionQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageCategoryCollectionQuery, TError, TData>({
        queryKey: ["PageCategoryCollection", variables],
        queryFn: customFetcher<PageCategoryCollectionQuery, PageCategoryCollectionQueryVariables>(
            PageCategoryCollectionDocument,
            variables
        ),
        ...options,
    });
};

usePageCategoryCollectionQuery.getKey = (variables: PageCategoryCollectionQueryVariables) => [
    "PageCategoryCollection",
    variables,
];

usePageCategoryCollectionQuery.fetcher = (
    variables: PageCategoryCollectionQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageCategoryCollectionQuery, PageCategoryCollectionQueryVariables>(
        PageCategoryCollectionDocument,
        variables,
        options
    );

export const PageCategoryDocument = `
    query PageCategory($locale: String!, $preview: Boolean, $id: String!) {
  page: pageCategory(id: $id, locale: $locale, preview: $preview) {
    ...PageCategoryFields
  }
}
    ${PageCategoryFieldsFragmentDoc}
${PageCategoryLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageCategoryQuery = <TData = PageCategoryQuery, TError = unknown>(
    variables: PageCategoryQueryVariables,
    options?: Omit<UseQueryOptions<PageCategoryQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageCategoryQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageCategoryQuery, TError, TData>({
        queryKey: ["PageCategory", variables],
        queryFn: customFetcher<PageCategoryQuery, PageCategoryQueryVariables>(
            PageCategoryDocument,
            variables
        ),
        ...options,
    });
};

usePageCategoryQuery.getKey = (variables: PageCategoryQueryVariables) => [
    "PageCategory",
    variables,
];

usePageCategoryQuery.fetcher = (
    variables: PageCategoryQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageCategoryQuery, PageCategoryQueryVariables>(
        PageCategoryDocument,
        variables,
        options
    );
