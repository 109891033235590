import { Tag as ChakraTag, TagLabel as ChakraTagLabel } from "@chakra-ui/react";
import type {
    TagProps as ChakraTagProps,
    TagLabelProps as ChakraTagLabelProps,
} from "@chakra-ui/react";

type TagProps = ChakraTagProps;
type TagLabelProps = ChakraTagLabelProps;

const Tag = ChakraTag;
const TagLabel = ChakraTagLabel;

export { Tag, TagLabel };
export type { TagProps, TagLabelProps };
