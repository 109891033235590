export const getDictionaryItem = (key: string, locale: string = "en") => {
    const localeKey = locale.split("-")[0].toLowerCase();
    return LOCALE_DICTIONARY[localeKey]?.[key] ?? "";
};

export const LOCALE_DICTIONARY: Record<string, Record<string, string>> = {
    en: {
        blankHrefTitle: "Opens in a new tab",
    } as const,
} as const;
