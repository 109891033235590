import { AspectRatio, CldImage, Grid, GridItem, NextLink } from "@project/ui";
import type { PartnerSetFieldsFragment } from "@/components/contentful/partner-set/__generated/partner-set.contentful.generated";
import { getDictionaryItem } from "@/common/enums/dictionary";
import { useRouter } from "next/router";
import { ModuleSpacer } from "@/components/module-spacer";
import { useContentfulInspectorMode } from "@contentful/live-preview/react";

export type PartnerSetProps = PartnerSetFieldsFragment;

export const PartnerSet = ({ partnersCollection, sys }: PartnerSetProps) => {
    const { locale } = useRouter();

    const inspectorMode = useContentfulInspectorMode({ entryId: sys.id });

    return (
        <ModuleSpacer bg="porscheBlack" color="allWhite" py={{ base: 10, s: 10 }}>
            <Grid
                {...inspectorMode({ fieldId: "partners", locale })}
                bg="porscheBlack"
                templateColumns={{
                    base: "repeat(4, 1fr)",
                    s: "repeat(6, 1fr)",
                    l: "repeat(9, 1fr)",
                }}
                gap={{ base: 5, l: 8 }}
                pb={{ base: 10, md: 10 }}
                borderBottomWidth="1px"
                borderColor="grey500"
            >
                {partnersCollection?.items?.map((partner) => (
                    <GridItem key={partner?.sys.id}>
                        {partner?.logo && (
                            <NextLink
                                href={partner.url!}
                                title={`${partner.name}: ${getDictionaryItem("blankHrefTitle", locale)}`}
                                target="_blank"
                            >
                                <AspectRatio
                                    ratio="16:9"
                                    transition={"opacity 0.25s ease"}
                                    _hover={{ opacity: 0.7 }}
                                >
                                    <CldImage
                                        cloudinaryAsset={partner.logo}
                                        sizes={["20vw", "16vw", "9vw"]}
                                        objectFit="contain"
                                        alt={`Logo for partner: ${partner.name!}`}
                                    />
                                </AspectRatio>
                            </NextLink>
                        )}
                    </GridItem>
                ))}
            </Grid>
        </ModuleSpacer>
    );
};
