import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartCircuitTimelineItem_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { PageArticleLinkToFieldsFragment } from "../../page-article/__generated/page-article.contentful.generated";
import { ContentTagFieldsFragment } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { PageBasicLinkToFieldsFragment } from "../../page-basic/__generated/page-basic.contentful.generated";
import { PageCarLinkToFieldsFragment } from "../../page-car/__generated/page-car.contentful.generated";
import {
    PartnerSetFieldsFragment,
    PartnerFieldsFragment,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { PageCategoryLinkToFieldsFragment } from "../../page-category/__generated/page-category.contentful.generated";
import { PageDriverLinkToFieldsFragment } from "../../page-driver/__generated/page-driver.contentful.generated";
import { PageHomepageLinkToFieldsFragment } from "../../page-homepage/__generated/page-homepage.contentful.generated";
import { PageRaceSeriesLinkToFieldsFragment } from "../../page-race-series/__generated/page-race-series.contentful.generated";
import {
    PageRaceEventFieldsFragment,
    PageRaceEventLinkToFieldsFragment,
} from "../../page-race-event/__generated/page-race-event.contentful.generated";
import { PageSearchLinkToFieldsFragment } from "../../page-search/__generated/page-search.contentful.generated";
import { PageTeamLinkToFieldsFragment } from "../../page-team/__generated/page-team.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { PageArticleLinkToFieldsFragmentDoc } from "../../page-article/__generated/page-article.contentful.generated";
import { ContentTagFieldsFragmentDoc } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { PageBasicLinkToFieldsFragmentDoc } from "../../page-basic/__generated/page-basic.contentful.generated";
import { PageCarLinkToFieldsFragmentDoc } from "../../page-car/__generated/page-car.contentful.generated";
import {
    PartnerSetFieldsFragmentDoc,
    PartnerFieldsFragmentDoc,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { PageCategoryLinkToFieldsFragmentDoc } from "../../page-category/__generated/page-category.contentful.generated";
import { PageDriverLinkToFieldsFragmentDoc } from "../../page-driver/__generated/page-driver.contentful.generated";
import { PageHomepageLinkToFieldsFragmentDoc } from "../../page-homepage/__generated/page-homepage.contentful.generated";
import { PageRaceSeriesLinkToFieldsFragmentDoc } from "../../page-race-series/__generated/page-race-series.contentful.generated";
import {
    PageRaceEventFieldsFragmentDoc,
    PageRaceEventLinkToFieldsFragmentDoc,
} from "../../page-race-event/__generated/page-race-event.contentful.generated";
import { PageSearchLinkToFieldsFragmentDoc } from "../../page-search/__generated/page-search.contentful.generated";
import { PageTeamLinkToFieldsFragmentDoc } from "../../page-team/__generated/page-team.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type ExternalLinkFieldsFragment = {
    __typename: "ExternalLink";
    label?: string | null;
    url?: string | null;
    sys: { __typename?: "Sys"; id: string };
};

export type NavigationFieldsFragment = {
    __typename?: "Navigation";
    title?: string | null;
    navigationItemsCollection?: {
        __typename?: "NavigationNavigationItemsCollection";
        items: Array<
            | ({ __typename?: "ExternalLink" } & ExternalLinkFieldsFragment)
            | ({ __typename?: "PageArticle" } & PageArticleLinkToFieldsFragment)
            | ({ __typename?: "PageBasic" } & PageBasicLinkToFieldsFragment)
            | ({ __typename?: "PageCar" } & PageCarLinkToFieldsFragment)
            | ({ __typename?: "PageCategory" } & PageCategoryLinkToFieldsFragment)
            | ({ __typename?: "PageDriver" } & PageDriverLinkToFieldsFragment)
            | ({ __typename?: "PageHomepage" } & PageHomepageLinkToFieldsFragment)
            | ({ __typename?: "PageRaceEvent" } & PageRaceEventFieldsFragment)
            | ({ __typename?: "PageRaceSeries" } & PageRaceSeriesLinkToFieldsFragment)
            | ({ __typename?: "PageSearch" } & PageSearchLinkToFieldsFragment)
            | ({ __typename?: "PageTeam" } & PageTeamLinkToFieldsFragment)
            | null
        >;
    } | null;
} & ComponentReferenceFields_Navigation_Fragment;

export type NavigationCollectionQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview: Types.Scalars["Boolean"]["input"];
    limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type NavigationCollectionQuery = {
    __typename?: "Query";
    navigationCollection?: {
        __typename?: "NavigationCollection";
        items: Array<({ __typename?: "Navigation" } & NavigationFieldsFragment) | null>;
    } | null;
};

export type NavigationQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview: Types.Scalars["Boolean"]["input"];
    id: Types.Scalars["String"]["input"];
}>;

export type NavigationQuery = {
    __typename?: "Query";
    navigation?: ({ __typename?: "Navigation" } & NavigationFieldsFragment) | null;
};

export const ExternalLinkFieldsFragmentDoc = `
    fragment ExternalLinkFields on ExternalLink {
  sys {
    id
  }
  __typename
  label
  url
}
    `;
export const NavigationFieldsFragmentDoc = `
    fragment NavigationFields on Navigation {
  ...ComponentReferenceFields
  title
  navigationItemsCollection(limit: 15) {
    items {
      ... on PageArticle {
        ...PageArticleLinkToFields
      }
      ... on PageBasic {
        ...PageBasicLinkToFields
      }
      ... on PageCar {
        ...PageCarLinkToFields
      }
      ... on PageCategory {
        ...PageCategoryLinkToFields
      }
      ... on PageDriver {
        ...PageDriverLinkToFields
      }
      ... on PageHomepage {
        ...PageHomepageLinkToFields
      }
      ... on PageRaceSeries {
        ...PageRaceSeriesLinkToFields
      }
      ... on PageRaceEvent {
        ...PageRaceEventFields
      }
      ... on PageSearch {
        ...PageSearchLinkToFields
      }
      ... on PageTeam {
        ...PageTeamLinkToFields
      }
      ... on ExternalLink {
        ...ExternalLinkFields
      }
    }
  }
}
    `;
export const NavigationCollectionDocument = `
    query NavigationCollection($locale: String!, $preview: Boolean!, $limit: Int = 1) {
  navigationCollection(locale: $locale, preview: $preview, limit: $limit) {
    items {
      ...NavigationFields
    }
  }
}
    ${NavigationFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PageArticleLinkToFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${PageBasicLinkToFieldsFragmentDoc}
${PageCarLinkToFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}
${PageCategoryLinkToFieldsFragmentDoc}
${PageDriverLinkToFieldsFragmentDoc}
${PageHomepageLinkToFieldsFragmentDoc}
${PageRaceSeriesLinkToFieldsFragmentDoc}
${PageRaceEventFieldsFragmentDoc}
${PageRaceEventLinkToFieldsFragmentDoc}
${PageSearchLinkToFieldsFragmentDoc}
${PageTeamLinkToFieldsFragmentDoc}
${ExternalLinkFieldsFragmentDoc}`;

export const useNavigationCollectionQuery = <TData = NavigationCollectionQuery, TError = unknown>(
    variables: NavigationCollectionQueryVariables,
    options?: Omit<UseQueryOptions<NavigationCollectionQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<NavigationCollectionQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<NavigationCollectionQuery, TError, TData>({
        queryKey: ["NavigationCollection", variables],
        queryFn: customFetcher<NavigationCollectionQuery, NavigationCollectionQueryVariables>(
            NavigationCollectionDocument,
            variables
        ),
        ...options,
    });
};

useNavigationCollectionQuery.getKey = (variables: NavigationCollectionQueryVariables) => [
    "NavigationCollection",
    variables,
];

useNavigationCollectionQuery.fetcher = (
    variables: NavigationCollectionQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<NavigationCollectionQuery, NavigationCollectionQueryVariables>(
        NavigationCollectionDocument,
        variables,
        options
    );

export const NavigationDocument = `
    query Navigation($locale: String!, $preview: Boolean!, $id: String!) {
  navigation(id: $id, locale: $locale, preview: $preview) {
    ...NavigationFields
  }
}
    ${NavigationFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PageArticleLinkToFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${PageBasicLinkToFieldsFragmentDoc}
${PageCarLinkToFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}
${PageCategoryLinkToFieldsFragmentDoc}
${PageDriverLinkToFieldsFragmentDoc}
${PageHomepageLinkToFieldsFragmentDoc}
${PageRaceSeriesLinkToFieldsFragmentDoc}
${PageRaceEventFieldsFragmentDoc}
${PageRaceEventLinkToFieldsFragmentDoc}
${PageSearchLinkToFieldsFragmentDoc}
${PageTeamLinkToFieldsFragmentDoc}
${ExternalLinkFieldsFragmentDoc}`;

export const useNavigationQuery = <TData = NavigationQuery, TError = unknown>(
    variables: NavigationQueryVariables,
    options?: Omit<UseQueryOptions<NavigationQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<NavigationQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<NavigationQuery, TError, TData>({
        queryKey: ["Navigation", variables],
        queryFn: customFetcher<NavigationQuery, NavigationQueryVariables>(
            NavigationDocument,
            variables
        ),
        ...options,
    });
};

useNavigationQuery.getKey = (variables: NavigationQueryVariables) => ["Navigation", variables];

useNavigationQuery.fetcher = (
    variables: NavigationQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<NavigationQuery, NavigationQueryVariables>(
        NavigationDocument,
        variables,
        options
    );
