import type { NextLinkProps } from "@project/ui";
import { Heading, NextLink, forwardRef } from "@project/ui";
import type { MouseEvent as ReactMouseEvent } from "react";
import { trackingNavClick } from "@/components/contentful/main-navigation/tracking-nav-click";
import { useRouter } from "next/router";
import { getFocusStyle } from "@porsche-design-system/components-react/styles";

type SectionLinkProps = NextLinkProps & {
    entryId: string;
    entryTypename: string;
};

const SectionLink = forwardRef<SectionLinkProps, "a">(
    ({ href, children, onClick, entryId, entryTypename, ...props }, ref) => {
        const { locale, asPath } = useRouter();

        const handleOnClick = (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
            const targetUrl = e.view.document.location.href;
            const targetType = e.type;
            const clickElementId = entryId;
            const clickElementName = entryTypename;

            trackingNavClick({
                targetUrl,
                targetType,
                clickElementId,
                clickElementName,
                locale: locale!,
                asPath,
            });

            onClick && onClick(e);
        };

        return (
            <NextLink
                href={href}
                onClick={handleOnClick}
                {...props}
                ref={ref}
                _focusVisible={{ ...getFocusStyle() }}
            >
                <Heading
                    size={{
                        base: "displayLarge",
                        l: "displaySmall",
                    }}
                    color="allWhite"
                    opacity={{ l: 0.5 }}
                    transition="var(--transition-property-common), var(--transition-duration-short) var(--transition-easing-base)"
                    _hover={{ opacity: 1 }}
                >
                    {children}
                </Heading>
            </NextLink>
        );
    }
);

export { SectionLink };
