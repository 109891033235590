import { useCallback, type MouseEventHandler } from "react";
import { useCldVideoPlayer } from "./cld-video-context";
import { Button } from "../../components/porsche-design-system/button";
import type { ButtonProps } from "../../components/porsche-design-system/button";
import { ButtonPure } from "../../components/porsche-design-system/button-pure";

type CldVideoTogglePlayProps = Omit<ButtonProps, "aria-label"> & {
    mobile?: boolean;
};

export const useTogglePlay = () => {
    const {
        state: { userPaused, isPlaying },
        dispatch,
    } = useCldVideoPlayer();

    return useCallback<MouseEventHandler>(() => {
        dispatch({ type: isPlaying ? "PAUSE" : "PLAY" });
        dispatch({ type: userPaused ? "USER_UNPAUSED" : "USER_PAUSED" });
    }, [dispatch, isPlaying, userPaused]);
};

const CldVideoTogglePlay = (props: CldVideoTogglePlayProps) => {
    const { mobile, ...rest } = props;

    const { state } = useCldVideoPlayer();

    const handleClick = useTogglePlay();

    if (mobile) {
        return (
            <ButtonPure
                onClick={handleClick}
                theme="dark"
                {...rest}
                hideLabel={true}
                icon={state.isPlaying ? "pause" : "play"}
                aria={{
                    "aria-label": state.isPlaying ? "pause video" : "play video",
                }}
                border="2px solid var(--colors-allWhite)"
                borderRadius="var(--radii-small)"
                size="x-large"
            />
        );
    }

    return (
        <Button
            onClick={handleClick}
            theme="dark"
            variant="secondary"
            {...rest}
            hideLabel={true}
            icon={state.isPlaying ? "pause" : "play"}
            aria={{
                "aria-label": state.isPlaying ? "pause video" : "play video",
            }}
        />
    );
};
CldVideoTogglePlay.displayName = "CldVideoTogglePlay";

export { CldVideoTogglePlay };
